import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import {
  Container,
  Button,
  Alert,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"
import uuid4 from "uuid/v4"
import { useMutation } from '@apollo/react-hooks';
import { REGISTER_USER } from "../../graphql/mutations"
import { useRegisterUserHandler } from "../../hooks"

const RegistrationForm = () => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
  }
  const [validate, setValidate] = useState(false)
  const [formState, setFormState] = useState(initialState)
  const [error, setError] = useState("")
  const [
    RegisterUser,
    { data: userData, loading: mutationLoading, error: mutationError },
  ] = useMutation(REGISTER_USER)

  const registerUser = useRegisterUserHandler()

  const handleRegisterUser = async event => {
    event.preventDefault()
    const { firstName, lastName, username, email, password } = formState
    const input = {
      clientMutationId: uuid4(),
      firstName,
      lastName,
      username,
      email,
      password,
    }
    setValidate(true)
    const result = await registerUser(RegisterUser, input)
    if (result && result.data && result.data.registerUser) {
      handleRegisterSuccess()
    } else {
      handleRegisterFail(result)
    }
  }

  const handleRegisterSuccess = () => {
    setValidate(false)
    setFormState(initialState)
    navigate("/login")
    // props.history.push('/profile');
  }

  const handleRegisterFail = err => {
    const newError = err.replace("&lt;strong&gt;ERROR&lt;/strong&gt;:", "")
    setValidate(true)
    setError(newError)
  }

  const handleTextChange = target => {
    setFormState(prevForState => ({
      ...prevForState,
      [target.name]: target.value,
    }))
  }

  const renderMessage = (loading, err, userData) => {
    if (err) {
      return (
        <Alert color="danger">
          <div dangerouslySetInnerHTML={{ __html: error }} />
        </Alert>
      )
    } else if (loading) {
      return <Alert color="primary">Loading...</Alert>
    } else if (userData) {
      return <Alert color="success">User successfully registerred!</Alert>
    }
  }

  return (
    <Container>
      <Form
        method="POST"
        noValidate
        validated={validate}
        onSubmit={event => handleRegisterUser(event)}
      >
        <h2>Register New User</h2>
        <FormGroup controlId="firstName">
          <Label>First Name</Label>
          <Input
            type="text"
            name="firstName"
            required
            placeholder="First Name"
            onChange={event => handleTextChange(event.target)}
            value={formState.firstName}
          />
          <FormFeedback type="invalid">
            First Name cannot be empty!
          </FormFeedback>
        </FormGroup>
        <FormGroup controlId="lastName">
          <Label>Last Name</Label>
          <Input
            type="text"
            name="lastName"
            required
            placeholder="Last Name"
            onChange={event => handleTextChange(event.target)}
            value={formState.lastName}
          />
          <FormFeedback type="invalid">Last Name cannot be empty!</FormFeedback>
        </FormGroup>
        <FormGroup controlId="email">
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            required
            placeholder="john.doe@gmail.com"
            onChange={event => handleTextChange(event.target)}
            value={formState.email}
          />
          <FormFeedback type="invalid">Email cannot be empty!</FormFeedback>
        </FormGroup>
        <FormGroup controlId="username">
          <Label>Username</Label>
          <Input
            type="text"
            name="username"
            required
            placeholder="john-doe"
            onChange={event => handleTextChange(event.target)}
            value={formState.username}
          />
          <FormFeedback type="invalid">Username cannot be empty!</FormFeedback>
        </FormGroup>
        <FormGroup controlId="password">
          <Label>Password</Label>
          <Input
            type="password"
            name="password"
            required
            placeholder="Password"
            onChange={event => handleTextChange(event.target)}
            value={formState.password}
          />
          <FormFeedback type="invalid">Password cannot be empty!</FormFeedback>
        </FormGroup>
        {renderMessage(mutationLoading, mutationError, userData)}
        <Button color="primary" type="submit">
          Register
        </Button>
      </Form>
      <Row className="mt-3">
        <Col>
          <Link to="/login" className="btn btn-primary">
            Already have an account?
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default RegistrationForm
