import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import RegistrationForm from "../components/Forms/RegistrationForm";

const RegisterPage = () => (
    <Layout location="register">
      <SEO title="Register User" />
      <div className="wrapper-home">
        <h1>Register New User</h1>
        <RegistrationForm />
      </div>
    </Layout>
  )
  
  export default RegisterPage
  